import React, { useState, useRef, useEffect } from "react";
import { QuestionDataNew, TABS } from "./types";
import "brace/mode/pgsql";
import "brace/theme/terminal";
import "react-toastify/dist/ReactToastify.css";
import SolutionView from "./SolutionView";
import SampleOutputTable from "./SampleOutputTable";

export const QuestionInfoNew: React.FC<{
  questionData: QuestionDataNew | null;
}> = ({ questionData }) => {
  const [activeTab, setActiveTab] = useState(TABS.DESCRIPTION);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleImageClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        handleClosePopup();
      }
    };

    if (isPopupOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopupOpen]);

  return (
    <div className="w-full px-4 bg-slate-100 rounded-md max-h-96 overflow-y-auto">
      <div className="flex border-b mb-4 text-sm">
        {[
          TABS.DESCRIPTION,
          TABS.ERD,
          TABS.OUTPUT_SAMPLE,
          TABS.HINT,
          TABS.SOLUTION,
        ].map((tab) => (
          <div
            key={tab}
            className={`px-2 py-2 cursor-pointer ${
              activeTab === tab
                ? "text-dnPurple border-b-2 border-dnPurple"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      <div>
        {activeTab === TABS.DESCRIPTION && (
          <div
            className="text-start text-md p-2 text-gray-800"
            dangerouslySetInnerHTML={{
              __html:
                questionData?.description ||
                "You are now in SANDBOX MODE! Try fetching data from one of the tables described in the data diagram, or choose a question to start practicing.",
            }}
          ></div>
        )}
        {activeTab === TABS.ERD && (
          <div className="cursor-pointer" onClick={handleImageClick}>
            <img src={`/erd.png`} alt="ERD Diagram" className="w-full" />
          </div>
        )}
        {activeTab === TABS.OUTPUT_SAMPLE && questionData && (
          <div className="p-2 rounded-lg break-words whitespace-pre-wrap overflow-auto">
            <SampleOutputTable
              outputSampleHeaders={questionData.outputSampleHeaders}
              outputSampleValues={questionData.outputSampleValues}
            />
          </div>
        )}
        {activeTab === TABS.HINT && questionData && (
          <div className="p-2 rounded-lg break-words whitespace-pre-wrap overflow-auto">
            {questionData.hint}
          </div>
        )}
        {activeTab === TABS.SOLUTION && questionData && (
          <SolutionView solution={questionData.solution} />
        )}
      </div>

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div ref={popupRef} className="relative bg-white p-4 rounded-lg">
            <button
              className="absolute top-2 right-2 text-black"
              onClick={handleClosePopup}
            >
              &times;
            </button>
            <img
              src={`/erd.png`}
              alt="ERD Diagram"
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};
