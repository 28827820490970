import { useLocation, Form, useActionData, Link } from "react-router-dom";

import { AuthenticationLayout } from "../layout";
import Button from "../../uiKit/button";

export const EmailConfirmationForm = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("e");
  const expirationDate = params.get("d");

  const actionData = useActionData() as Record<
    string,
    { _errors?: string[]; success?: string }
  >;

  if (!email || !expirationDate) {
    return (
      <AuthenticationLayout
        title="Email confirmation"
        subTitle="Something went wrong, please contact support at admin@data-noobs.com"
      />
    );
  }

  if (Number(expirationDate) < new Date().getTime()) {
    return (
      <AuthenticationLayout
        title="Email confirmation"
        subTitle="This link has been expired, please sign up again"
      />
    );
  }

  if (actionData?.actionSuccess?.success) {
    return (
      <AuthenticationLayout
        title="Welcome!"
        subTitle={actionData.actionSuccess.success}
      >
        <Link to="/login">
          <button className="bg-dnPurple text-white px-4 py-2 rounded-lg focus:outline-none hover:bg-dnPurpleDark h-fit">
            Sign In
          </button>
        </Link>
      </AuthenticationLayout>
    );
  }

  return (
    <AuthenticationLayout
      title="Welcome aboard!"
      subTitle="Activate your account now to unlock a world of possibilities"
    >
      <Form method="post" replace className="flex flex-col gap-4 mx-2">
        <input type="hidden" name="email" value={atob(email)} />
        <input type="hidden" name="expirationDate" value={expirationDate} />
        <Button className="h-fit" type="submit">
          Activate your account
        </Button>
      </Form>
    </AuthenticationLayout>
  );
};
