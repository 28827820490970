import { useState, forwardRef, Ref } from "react";
import { Input } from "./input";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import type { InputType } from "./input";

export const InputPassword = forwardRef(
  ({ Addon, ...rest }: InputType, ref: Ref<HTMLInputElement> | undefined) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <Input
        type={showPassword ? "text" : "password"}
        {...rest}
        ref={ref}
        Addon={() => (
          <div className="flex items-center gap-2">
            {Addon && <Addon />}
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="focus:outline-none mx-2"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        )}
      />
    );
  },
);
