import { useState, useEffect } from "react";
import { QuestionData } from "../sqlEditor/types";
import { questions } from "../sqlEditor/questionsData";
import TagManager from "react-gtm-module";
import { useMixpanel } from "react-mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { DifficultyLevel } from "../sqlEditor/difficultyLevel";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ValueProposition from "./ValueProposition";
import TableHeader from "./TableHeader";
import { useUserContext } from "../authentication/userContext";
import { Tooltip } from "react-tooltip";
import PageHeader from "./PageHeader";
import { ANSWERS_STORAGE_NAME } from "../authentication/constants";
import { CiLock } from "react-icons/ci";

const FREE_QUESTION_IDS = [1, 2, 6, 9];

const QuestionTable: React.FC = () => {
  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const { user } = useUserContext();

  const answeredQuestionsLocalStorage =
    localStorage.getItem(ANSWERS_STORAGE_NAME);
  const answeredQuestions = answeredQuestionsLocalStorage
    ? JSON.parse(answeredQuestionsLocalStorage)
    : {};
  const totalQuestions = questions.length;
  const answeredCount = questions.filter((q) => answeredQuestions[q.id]).length;
  const progressPercentage =
    totalQuestions > 0 ? (answeredCount / totalQuestions) * 100 : 0;

  const [difficultyFilter, setDifficultyFilter] = useState<number | null>(null);
  const [answeredFilter, setAnsweredFilter] = useState<
    "answered" | "unanswered" | null
  >(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [filteredQuestions, setFilteredQuestions] = useState<QuestionData[]>(
    questions.sort((a, b) => {
      return a.difficulty - b.difficulty;
    }),
  );

  useEffect(() => {
    const newFilteredQuestions = questions
      .filter((question) => {
        if (
          difficultyFilter !== null &&
          question.difficulty !== difficultyFilter
        )
          return false;

        if (answeredFilter === "answered" && !answeredQuestions[question.id])
          return false;

        if (answeredFilter === "unanswered" && answeredQuestions[question.id])
          return false;

        if (
          selectedTags.length > 0 &&
          !selectedTags.every((tag) => question.tags.includes(tag))
        )
          return false;

        return true;
      })
      .sort((a, b) => {
        return a.difficulty - b.difficulty;
      });
    setFilteredQuestions(newFilteredQuestions);

    const tagsSet = new Set<string>();
    newFilteredQuestions.forEach((question) => {
      question.tags.forEach((tag) => tagsSet.add(tag));
    });
    setAvailableTags(Array.from(tagsSet));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficultyFilter, answeredFilter, selectedTags]);

  const isQuestionEnabled = (question: QuestionData) => {
    const isDisabled = !user && !FREE_QUESTION_IDS.includes(question.id);
    return !isDisabled;
  };

  const onQuestionSelected = (item: QuestionData | null) => {
    if (!item) return;

    TagManager.dataLayer({
      dataLayer: {
        event: "question_chosen",
        questionId: item?.id,
      },
    });

    if (mixpanel) {
      mixpanel.track("question_chosen", {
        questionId: item?.id,
        email: user?.email,
        name: user?.fullName,
      });
    }

    navigate(`/question/${item.id}`);
  };

  return (
    <div>
      <PageHeader />
      <ValueProposition />
      <div className="text-xl mx-4 my-8 text-center text-dnBlue">
        <span className="bg-sky-100 rounded-lg px-4 py-1.5 inline-block">
          Our mock bookstore database needs your help! Write queries to unlock
          business insights and drive smart decisions
        </span>
      </div>
      <TableHeader
        difficultyFilter={difficultyFilter}
        setDifficultyFilter={setDifficultyFilter}
        answeredFilter={answeredFilter}
        setAnsweredFilter={setAnsweredFilter}
        availableTags={availableTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div className="flex flex-col md:flex-row mb-4">
        <div className="flex overflow-x-auto mx-4 rounded w-fit md:w-4/5 h-fit">
          <table className="table-auto w-full border-collapse border border-gray-200 rounded">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 text-left font-semibold">Title</th>
                <th className="py-3 px-4 text-left font-semibold">
                  Answered correctly
                </th>
                <th className="py-3 px-4 text-left font-semibold">Level</th>
              </tr>
            </thead>
            <tbody>
              {filteredQuestions.map((question) => {
                const questionEnabled = isQuestionEnabled(question);
                return (
                  <tr
                    key={question.id}
                    className={`border-b border-gray-200 hover:bg-gray-50 ${questionEnabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                    onClick={() =>
                      questionEnabled && onQuestionSelected(question)
                    }
                    data-tooltip-id={`tooltip-${question.id}`}
                    data-tooltip-content="Sign in to unlock this question"
                    data-tooltip-place="top"
                  >
                    <td className={"py-2 px-4 text-dnBlue"}>
                      <span className="flex md:flex-row flex-col">
                        {question.title}
                        {!questionEnabled && (
                          <span className="bg-gray-200 rounded flex flex-row md:mx-2 items-center px-1 gap-1 h-fit w-fit">
                            Locked <CiLock />
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="py-2 px-4">
                      {answeredQuestions[question.id] ? (
                        <span className="flex items-center justify-center w-7 h-7 rounded-full bg-green-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5 text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="py-2 px-4">
                      <DifficultyLevel level={question.difficulty} />
                    </td>
                    {!questionEnabled && (
                      <td>
                        <Tooltip id={`tooltip-${question.id}`} />
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex w-full md:w-1/5 flex-col gap-4 mr-4 mt-4 md:mt-0">
          <div className="flex flex-col items-center justify-start py-8 bg-gray-50 rounded-lg">
            <div className="w-24 h-24 mb-4 relative">
              <CircularProgressbar
                value={progressPercentage}
                styles={buildStyles({
                  textSize: "20px",
                  pathColor: "#6456FF",
                  textColor: "#1B3144",
                  trailColor: "#e5e7eb",
                  backgroundColor: "#f3f4f6",
                  strokeLinecap: "round",
                  pathTransitionDuration: 0.5,
                })}
              />
            </div>
            <div className="text-center text-sm text-dnBlue w-32">
              {answeredCount} / {totalQuestions} Questions Answered Correctly
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionTable;
