import React from "react";

interface TableProps {
  outputSampleHeaders: string[];
  outputSampleValues: string[];
}

const SampleOutputTable: React.FC<TableProps> = ({
  outputSampleHeaders,
  outputSampleValues,
}) => {
  const rows = Math.ceil(
    outputSampleValues.length / outputSampleHeaders.length,
  );

  const tableData = Array.from({ length: rows }, (_, rowIndex) =>
    outputSampleHeaders.map(
      (_, colIndex) =>
        outputSampleValues[rowIndex * outputSampleHeaders.length + colIndex] ||
        "",
    ),
  );

  return (
    <div className="overflow-x-auto text-sm">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            {outputSampleHeaders.map((header, index) => (
              <th key={index} className="py-2 px-2 border-b text-left">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="py-2 px-2 border-b text-left">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SampleOutputTable;
