import { random } from "lodash";
import React from "react";
import { useTable } from "react-table";

const COLUMN_NAME_SEPERATOR = "_____";

const SqlTable = ({ data }: { data: Record<string, unknown>[] }) => {
  const columns = React.useMemo(() => {
    const emptyState = [
      {
        Header: "No rows returned",
        accessor: "No rows returned",
      },
    ];

    const allKeys = new Set<string>();
    for (const obj of data) {
      Object.keys(obj).forEach((key) => allKeys.add(key));
    }
    const uniqueKeys = Array.from(allKeys);
    if (uniqueKeys?.length === 0) return emptyState;
    return uniqueKeys.map((column) => {
      const header = column.includes(COLUMN_NAME_SEPERATOR)
        ? column.split(COLUMN_NAME_SEPERATOR)[0]
        : column;
      return {
        Header: header,
        accessor: column,
      };
    });
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="overflow-auto max-h-80 md:mr-4" id="sql-table">
      <table
        {...getTableProps()}
        className="table-auto w-full border-collapse border border-gray-200"
        key="sql-table"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={random()} className="bg-gray-100">
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  className="px-3 md:px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                key={row.id}
                className="border-b border-gray-200 hover:bg-gray-100"
              >
                {row.cells.map((cell) => (
                  <td
                    key={cell.value}
                    className="px-3 md:px-6 py-4 text-left text-sm text-dnBlue"
                  >
                    {cell.value === null || cell.value === undefined
                      ? "NULL"
                      : cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SqlTable;
