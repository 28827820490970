import React, { useState } from "react";
import Button from "../uiKit/button";
import SampleOutputTable from "../sqlEditor/SampleOutputTable";
import { isArray } from "lodash";
import { Question } from "./types";

interface QuestionFormProps {
  question?: Question | null;
  onSave: (question: Question) => void;
  onCancel: () => void;
}

const QuestionForm: React.FC<QuestionFormProps> = ({
  question,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState<Question>({
    title: question?.title || "",
    description: question?.description || "",
    hint: question?.hint || "",
    solution: question?.solution || "",
    difficulty: question?.difficulty || 1,
    tags: question?.tags || [],
    ignoreOrder: question?.ignoreOrder || false,
    isVisible: question?.isVisible || true,
    position: question?.position || 0,
    outputSampleHeaders: question?.outputSampleHeaders || "",
    outputSampleValues: question?.outputSampleValues || "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: Number(value),
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: parseInt(value),
    }));
  };

  const handleTagKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newTag = (e.target as HTMLInputElement).value.trim();
      if (newTag && !formData.tags.includes(newTag)) {
        setFormData((prev) => ({
          ...prev,
          tags: [...prev.tags, newTag],
        }));
        (e.target as HTMLInputElement).value = "";
      }
    } else if (e.key === "Escape") {
      (e.target as HTMLInputElement).value = "";
    }
  };

  const handleTagRemove = (tagToRemove: string) => {
    setFormData((prev) => ({
      ...prev,
      tags: prev.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleIgnoreOrderChange = () => {
    setFormData((prev) => ({
      ...prev,
      ignoreOrder: !prev.ignoreOrder,
    }));
  };

  const handleIsVisibleChange = () => {
    setFormData((prev) => ({
      ...prev,
      isVisible: !prev.isVisible,
    }));
  };

  const handleSubmit = () => {
    onSave({ ...formData, id: question?.id });
  };

  const formatOutputSample = (outputSample: string | string[]): string[] => {
    if (isArray(outputSample))
      return outputSample.filter((header) => !!header && header !== "");
    return outputSample
      .split(",")
      .map((header) => header.trim())
      .filter((header) => !!header && header !== "");
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-md">
      <div className="mb-4">
        <label className="block text-sm font-medium">Title</label>
        <input
          name="title"
          type="text"
          value={formData.title}
          onChange={handleInputChange}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Description</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          className="w-full p-2 border rounded min-h-48"
        />
      </div>
      <div className="mb-4 bg-gray-200">
        <div className="font-mono">Description preview:</div>
        <div
          className="text-start text-md p-2 text-gray-800"
          dangerouslySetInnerHTML={{
            __html:
              formData.description ||
              "You are now in SANDBOX MODE! Try fetching data from one of the tables described in the data diagram, or choose a question to start practicing.",
          }}
        ></div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Hint</label>
        <textarea
          name="hint"
          value={formData.hint}
          onChange={handleInputChange}
          className="w-full p-2 border rounded min-h-24"
        />
      </div>

      <div className="mb-4 bg-gray-200">
        <div className="font-mono">Hint preview:</div>
        <div
          className="text-start text-md p-2 text-gray-800"
          dangerouslySetInnerHTML={{
            __html:
              formData.hint ||
              "You are now in SANDBOX MODE! Try fetching data from one of the tables described in the data diagram, or choose a question to start practicing.",
          }}
        ></div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Solution</label>
        <textarea
          name="solution"
          value={formData.solution}
          onChange={handleInputChange}
          className="w-full p-2 border rounded min-h-64"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Output sample headers (comma separated strings)
        </label>
        <textarea
          name="outputSampleHeaders"
          value={formData.outputSampleHeaders}
          onChange={handleInputChange}
          className="w-full p-2 border rounded min-h-16"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">
          Output sample values (comma separated strings)
        </label>
        <textarea
          name="outputSampleValues"
          value={formData.outputSampleValues}
          onChange={handleInputChange}
          className="w-full p-2 border rounded min-h-16"
        />
      </div>

      <div className="mb-4 bg-gray-200">
        <div className="font-mono">Sample output preview:</div>
        {formData?.outputSampleHeaders && formData?.outputSampleValues && (
          <div className="p-2 overflow-auto h-fit">
            <SampleOutputTable
              outputSampleHeaders={formatOutputSample(
                formData.outputSampleHeaders
              )}
              outputSampleValues={formatOutputSample(
                formData.outputSampleValues
              )}
            />
          </div>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Position</label>
        <input
          name="position"
          type="number"
          value={formData.position}
          onChange={handleNumberChange}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Difficulty</label>
        <select
          name="difficulty"
          value={formData.difficulty}
          onChange={handleSelectChange}
          className="w-full p-2 border rounded"
        >
          <option value={1}>Easy</option>
          <option value={2}>Medium</option>
          <option value={3}>Advanced</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium">Tags</label>
        <div className="flex flex-wrap gap-2 mb-2">
          {formData.tags.map((tag) => (
            <span
              key={tag}
              className="bg-dnPurple text-white px-2 py-1 rounded-lg flex items-center"
            >
              {tag}
              <button
                type="button"
                onClick={() => handleTagRemove(tag)}
                className="ml-2 text-gray-300"
              >
                &times;
              </button>
            </span>
          ))}
        </div>
        <input
          type="text"
          placeholder="Add a tag and press Enter"
          onKeyDown={handleTagKeyDown}
          className="w-full p-2 border rounded"
        />
      </div>

      <div className="mb-4 flex items-center">
        <label className="block text-sm font-medium mr-2">Ignore Order</label>
        <input
          type="checkbox"
          checked={formData.ignoreOrder}
          onChange={handleIgnoreOrderChange}
          className="form-checkbox"
        />
      </div>

      <div className="mb-4 flex items-center">
        <label className="block text-sm font-medium mr-2">Is Visible</label>
        <input
          type="checkbox"
          checked={formData.isVisible}
          onChange={handleIsVisibleChange}
          className="form-checkbox"
        />
      </div>

      <div className="flex space-x-4">
        <Button
          variant="secondary"
          onClick={onCancel}
          className="px-4 py-2 bg-gray-300 rounded"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default QuestionForm;
