import React, { Dispatch, SetStateAction } from "react";
import Select, { MultiValue, SingleValue, StylesConfig } from "react-select";
import { getLevelDetails } from "../sqlEditor/difficultyLevel";

const customStyles: StylesConfig<any, boolean> = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #6456FF",
    boxShadow: state.isFocused ? "0 0 0 1px #6456FF" : "none",
    backgroundColor: "white",
    minWidth: "14rem",
    ":hover": {
      border: "1px solid #6456FF",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#6456FF",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#6456FF",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6456FF",
    ":hover": {
      color: "#6456FF",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#6456FF",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.25rem",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.1)",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#fff",
    ":hover": {
      backgroundColor: "#6456FF",
      color: "#fff",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? "#6456FF"
      : state.isSelected
        ? "#6456FF"
        : "white",
    color: state.isFocused || state.isSelected ? "white" : "#6456FF",
    ":hover": {
      backgroundColor: "#6456FF",
      color: "white",
    },
  }),
};

const TableHeader: React.FC<{
  difficultyFilter: number | null;
  setDifficultyFilter: Dispatch<SetStateAction<number | null>>;
  answeredFilter: "answered" | "unanswered" | null;
  setAnsweredFilter: Dispatch<SetStateAction<"answered" | "unanswered" | null>>;
  availableTags: string[];
  selectedTags: string[];
  setSelectedTags: Dispatch<SetStateAction<string[]>>;
}> = ({
  difficultyFilter,
  setDifficultyFilter,
  answeredFilter,
  setAnsweredFilter,
  availableTags,
  selectedTags,
  setSelectedTags,
}) => {
  const handleTagChange = (
    selectedOptions: MultiValue<{ value: string; label: string }>,
  ) => {
    setSelectedTags(selectedOptions.map((option) => option.value));
  };

  const handleDifficultyChange = (
    selectedOption: SingleValue<{ value: number | null; label: string }>,
  ) => {
    setDifficultyFilter(selectedOption?.value ?? null);
  };

  const handleAnsweredChange = (
    selectedOption: SingleValue<{
      value: string | null;
      label: string;
    }>,
  ) => {
    setAnsweredFilter(
      (selectedOption?.value as "answered" | "unanswered") ?? null,
    );
  };

  const tagOptions = availableTags.map((tag) => ({ value: tag, label: tag }));
  const difficultyOptions = [
    { value: null, label: "All Levels" },
    { value: 1, label: getLevelDetails(1).difficultyText },
    { value: 2, label: getLevelDetails(2).difficultyText },
    { value: 3, label: getLevelDetails(3).difficultyText },
  ];
  const answeredOptions = [
    { value: null, label: "All Questions" },
    { value: "answered", label: "Answered" },
    { value: "unanswered", label: "Unanswered" },
  ];

  return (
    <div className="flex flex-col space-y-4 mx-4 mt-6 mb-4">
      <div className="flex justify-start items-center flex-wrap">
        <div className="text-dnBlue bg-white text-3xl font-semibold mr-4 min-w-56 rounded px-2">
          Exercise Questions
        </div>
        <div className="flex flex-wrap gap-1">
          <Select
            options={difficultyOptions}
            value={difficultyOptions.find(
              (option) => option.value === difficultyFilter,
            )}
            onChange={handleDifficultyChange}
            className="min-w-60 mx-2"
            styles={customStyles}
            isClearable={!!difficultyFilter}
          />
          <Select
            options={answeredOptions}
            value={answeredOptions.find(
              (option) => option.value === answeredFilter,
            )}
            onChange={handleAnsweredChange}
            className="min-w-60 mx-2"
            styles={customStyles}
            isClearable={!!answeredFilter}
          />
          <Select
            isMulti
            isSearchable
            options={tagOptions}
            value={selectedTags.map((tag) => ({ value: tag, label: tag }))}
            onChange={handleTagChange}
            className="min-w-60 mx-2"
            styles={customStyles}
            placeholder="Select tags to filter by..."
          />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
