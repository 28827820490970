import { QueryClient } from "@tanstack/react-query";
import { getUserData } from "../authentication/userContext";

export type RootLoaderData = {
  user: Awaited<ReturnType<typeof getUserData>>;
};

export const rootLoader =
  (queryClient: QueryClient) => async (): Promise<RootLoaderData> => {
    const user = await getUserData(queryClient);

    return { user };
  };
