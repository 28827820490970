import React from "react";

export const DifficultyLevel: React.FC<{
  level?: number | null;
}> = ({ level }) => {
  if (!level) return null;

  const { difficultyText, difficultyColor } = getLevelDetails(level);

  return (
    <div
      className={`font-semibold text-white rounded-2xl w-fit items-center px-2 h-fit ${difficultyColor}`}
    >
      {difficultyText}
    </div>
  );
};

export const getLevelDetails = (level: number) => {
  let difficultyText;
  let difficultyColor;
  switch (level) {
    case 1:
      difficultyText = "Easy";
      difficultyColor = "bg-green-400";
      break;
    case 2:
      difficultyText = "Medium";
      difficultyColor = "bg-yellow-400";
      break;
    case 3:
      difficultyText = "Advanced";
      difficultyColor = "bg-red-400";
      break;
    default:
      difficultyText = "Unknown";
      difficultyColor = "bg-gray-400";
      break;
  }
  return { difficultyText, difficultyColor };
};
