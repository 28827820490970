import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { getFeedbacksQuery } from "../api/feedbackApi";
import { SyncLoader } from "react-spinners";
import { Feedback } from "./types";
import { useUserContext } from "../authentication/userContext";

export const FeedbackTable: React.FC = () => {
  const [sortField, setSortField] = useState<keyof Feedback>("createdAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [filterByQuestionId, setFilterByQuestionId] = useState("");
  const [filterByEmail, setFilterByEmail] = useState("");

  const { user } = useUserContext();

  const {
    data: feedbacks = { data: [], success: true },
    isLoading,
    isError,
  } = useQuery(getFeedbacksQuery());

  const sortedAndFilteredFeedbacks = useMemo(() => {
    if (!feedbacks.success) return [];
    let filteredFeedbacks = feedbacks.data as Feedback[];

    if (filterByQuestionId) {
      filteredFeedbacks = filteredFeedbacks.filter((fb) =>
        fb.questionId.toLowerCase().includes(filterByQuestionId.toLowerCase())
      );
    }

    if (filterByEmail) {
      filteredFeedbacks = filteredFeedbacks.filter((fb) =>
        fb.userEmail.toLowerCase().includes(filterByEmail.toLowerCase())
      );
    }

    return filteredFeedbacks.sort((a, b) => {
      const compareA = a[sortField];
      const compareB = b[sortField];

      if (sortDirection === "asc") {
        return compareA > compareB ? 1 : -1;
      } else {
        return compareA < compareB ? 1 : -1;
      }
    });
  }, [feedbacks, sortField, sortDirection, filterByQuestionId, filterByEmail]);

  if (isLoading || !sortedAndFilteredFeedbacks) {
    return (
      <div className="flex justify-center mt-8">
        <SyncLoader color={"#6456FF"} size={30} />
      </div>
    );
  }

  if (
    !user ||
    (!user?.email?.includes("weisslertal") &&
      !user?.email?.includes("alonperrymail"))
  ) {
    return <div className="text-lg m-8">Unauthorized</div>;
  }

  if (isError || !feedbacks.success) {
    return <div className="text-lg m-8">Error loading feedbacks</div>;
  }

  const handleSort = (field: keyof Feedback) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Feedback Table</h1>

      <div className="mb-4 flex gap-4">
        <input
          type="text"
          className="border rounded px-2 py-1"
          placeholder="Filter by Question ID"
          value={filterByQuestionId}
          onChange={(e) => setFilterByQuestionId(e.target.value.trim())}
        />
        <input
          type="text"
          className="border rounded px-2 py-1"
          placeholder="Filter by Email"
          value={filterByEmail}
          onChange={(e) => setFilterByEmail(e.target.value.trim())}
        />
      </div>

      <table className="min-w-full bg-white border rounded-lg">
        <thead>
          <tr className="bg-gray-100">
            <th
              className="px-4 py-2 cursor-pointer"
              onClick={() => handleSort("questionId")}
            >
              Question ID{" "}
              {sortField === "questionId" &&
                (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th
              className="px-4 py-2 cursor-pointer"
              onClick={() => handleSort("userEmail")}
            >
              User Email{" "}
              {sortField === "userEmail" &&
                (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th
              className="px-4 py-2 cursor-pointer"
              onClick={() => handleSort("createdAt")}
            >
              Created At{" "}
              {sortField === "createdAt" &&
                (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th className="px-4 py-2">Content</th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredFeedbacks.map((feedback) => (
            <tr key={feedback.createdAt.toString()} className="border-t">
              <td className="px-4 py-2">{feedback.questionId}</td>
              <td className="px-4 py-2">{feedback.userEmail}</td>
              <td className="px-4 py-2">
                {new Date(feedback.createdAt).toLocaleString()}
              </td>
              <td className="px-4 py-2">{feedback.content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FeedbackTable;
