import {
  useActionData,
  useLocation,
  useNavigation,
  Form,
  Link,
} from "react-router-dom";

import { AuthenticationLayout } from "../layout";
import { Input } from "../../uiKit/input";
import Button from "../../uiKit/button";

export const ForgotPasswordForm = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/";

  const navigation = useNavigation();
  const isLoggingIn = navigation.formData?.get("email") != null;

  const actionData = useActionData() as Record<
    string,
    { _errors?: string[]; success?: string }
  >;

  if (actionData?.actionError?._errors) {
    return (
      <AuthenticationLayout
        title="Forgot password?"
        subTitle={actionData?.actionError?._errors[0]}
      />
    );
  }

  if (actionData?.actionSuccess?.success) {
    return (
      <AuthenticationLayout
        data-testid="forgot-password-confirmation"
        title="Check your email"
        subTitle={actionData.actionSuccess.success}
      />
    );
  }

  return (
    <AuthenticationLayout
      data-testid="forgot-password-title"
      title="Forgot password?"
      subTitle={
        <>
          Enter your email and we&apos;ll send you password reset instructions
        </>
      }
    >
      <Form method="post" replace className="flex flex-col gap-4 mx-2">
        <input type="hidden" name="redirectTo" value={from} />

        <Input
          data-testid="forgot-password-email"
          isError={!!actionData?.email?._errors?.length}
          helpText={actionData?.email?._errors?.[0]}
          type="text"
          name="email"
          placeholder="Email"
        />

        <Button type="submit" disabled={isLoggingIn}>
          Request to reset password
        </Button>

        <div className="text-sm text-dnPurple">
          <Link to="/login">Already have an account? Sign In</Link>
        </div>
      </Form>
    </AuthenticationLayout>
  );
};
