import { MixpanelProvider } from "react-mixpanel-browser";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Header from "./components/Header";
import SqlEditor from "./sqlEditor/sqlEditor";
import SqlEditorNew from "./sqlEditor/sqlEditorNew";
import QuestionTable from "./components/QuestionTable";
import QuestionTableNew from "./components/QuestionsTableNew";
import environment from "./config/environment";
import Footer from "./components/Footer";
import { EmailConfirmationForm } from "./authentication/emailConfirmation/component";
import SignUpView from "./authentication/signUp/component";
import { LoginForm } from "./authentication/login/component";
import { loginAction } from "./authentication/login/action";
import { signUpAction } from "./authentication/signUp/action";
import { emailConfirmationAction } from "./authentication/emailConfirmation/action";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { rootLoader } from "./components/rootLoader";
import { Layout } from "./components/RootLayout";
import { ForgotPasswordForm } from "./authentication/forgotPassword/component";
import { ResetPasswordForm } from "./authentication/resetPassword/component";
import { forgotPasswordAction } from "./authentication/forgotPassword/action";
import { resetPasswordAction } from "./authentication/resetPassword/action";
import QuestionManager from "./admin/questionsManager";
import { ToastContainer } from "react-toastify";
import FeedbackTable from "./admin/FeedbackTable";

const MIXPANEL_TOKEN =
  environment.env === "local" ? undefined : "d982a31414e729dd96995133b7692dcc";
const MIXPANEL_CONFIG = {
  track_pageview: true,
  persistence: "localStorage",
};

export const QUESTION_ID_PATH_PARAM = "questionId";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader: rootLoader(queryClient),
    element: <Layout />,
    children: [
      { index: true, element: <Navigate to="/questions" replace /> },
      {
        path: "/questions",
        element: (
          <div>
            <Header />
            <QuestionTable />
          </div>
        ),
      },
      {
        path: "/questions-new",
        element: (
          <div>
            <Header />
            <QuestionTableNew />
          </div>
        ),
      },
      {
        path: `/question/:${QUESTION_ID_PATH_PARAM}`,
        element: (
          <div>
            <Header />
            <SqlEditor />
          </div>
        ),
      },
      {
        path: `/question-new/:${QUESTION_ID_PATH_PARAM}`,
        element: (
          <div>
            <Header />
            <SqlEditorNew />
          </div>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <div>
        <Header showLoginButton={false} />
        <LoginForm />
      </div>
    ),
    action: loginAction(queryClient),
  },
  {
    path: "/signup",
    element: (
      <div>
        <Header showLoginButton={false} />
        <SignUpView />
      </div>
    ),
    action: signUpAction(),
  },
  {
    path: "/email-confirmation",
    element: (
      <div>
        <Header showLoginButton={false} />
        <EmailConfirmationForm />
      </div>
    ),
    action: emailConfirmationAction(),
  },
  {
    path: "/forgot-password",
    element: (
      <div>
        <Header showLoginButton={false} />
        <ForgotPasswordForm />
      </div>
    ),
    action: forgotPasswordAction(),
  },
  {
    path: "/reset-password",
    element: (
      <div>
        <Header showLoginButton={false} />
        <ResetPasswordForm />
      </div>
    ),
    action: resetPasswordAction(),
  },
  {
    path: "/admin",
    element: (
      <div>
        <Header />
        <QuestionManager />
      </div>
    ),
    action: resetPasswordAction(),
  },
  {
    path: "/admin/feedback",
    element: (
      <div>
        <Header />
        <FeedbackTable />
      </div>
    ),
    action: resetPasswordAction(),
  },
  {
    path: "*",
    element: <div>404 Not found</div>,
  },
]);

function DataNoobsApp() {
  return (
    <QueryClientProvider client={queryClient}>
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <ToastContainer
              autoClose={8000}
              limit={1}
              pauseOnFocusLoss={false}
            />
            <RouterProvider router={router} />
          </div>
          <Footer />
        </div>
      </MixpanelProvider>
    </QueryClientProvider>
  );
}

export default DataNoobsApp;
