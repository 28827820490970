import { isEqual } from "lodash";

export interface NodeSqlParserError {
  message: string;
  found: string;
  location: {
    start: { offset: number; line: number; column: number };
    end: { offset: number; line: number; column: number };
  };
}

const sortArray = (array: Record<string, unknown>[]) => {
  const key = Object.keys(array)[0];

  return array.sort(
    (a: Record<string, unknown>, b: Record<string, unknown>) => {
      const strA = JSON.stringify(a[key]);
      const strB = JSON.stringify(b[key]);

      if (strA < strB) return -1;
      if (strA > strB) return 1;
      return 0;
    },
  );
};

export const areArraysEqual = (
  arr1: Record<string, unknown>[],
  arr2: Record<string, unknown>[],
  ignoreOrder: boolean,
): boolean => {
  if (arr1.length !== arr2.length) return false;

  const roundNumber = (value: unknown): unknown => {
    if (typeof value === "number") {
      return Math.round(value * 100) / 100;
    }
    return value;
  };

  if (ignoreOrder) {
    const sortedArr1 = sortArray(arr1);
    const sortedArr2 = sortArray(arr2);

    for (let i = 0; i < sortedArr1.length; i++) {
      const values1 = Object.values(sortedArr1[i]).map(roundNumber);
      const values2 = Object.values(sortedArr2[i]).map(roundNumber);
      const isEql = isEqual(values1.sort(), values2.sort());
      if (!isEql) return false;
    }

    return true;
  }

  for (let i = 0; i < arr1.length; i++) {
    const values1 = Object.values(arr1[i]).map(roundNumber);
    const values2 = Object.values(arr2[i]).map(roundNumber);
    const isEql = isEqual(values1.sort(), values2.sort());
    if (!isEql) return false;
  }

  return true;
};

export const parseSqlParserError = (error: NodeSqlParserError): string => {
  const {
    found,
    location: {
      start: { line, column },
    },
  } = error;

  const parsedFound = found === "\n" ? "\\n" : found;
  return `Syntax error at line ${line}, character ${column}: invalid key "${parsedFound}"`;
};
