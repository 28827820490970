import { LoaderFunctionArgs } from "react-router-dom";
import { forgotPassword } from "../../api/loginApi";
import { forgotPasswordSchema } from "../schemaValidators";

export const forgotPasswordAction =
  () =>
  async ({ request }: LoaderFunctionArgs) => {
    const formData = await request.formData();
    const email = formData.get("email") as string;

    try {
      const data = { email };
      const result = forgotPasswordSchema.safeParse(data);

      if (!result.success) {
        return result?.error?.format();
      }

      try {
        const result = await forgotPassword({ email });
        if (!result.success) {
          if (result.status === 429 || result.status === 400) {
            return {
              actionError: {
                _errors: [result.data],
              },
            };
          } else {
            return {
              actionError: {
                _errors: [
                  "Something went wrong, please contact support at admin@data-noobs.com",
                ],
              },
            };
          }
        }
      } catch (error) {
        return {
          actionError: {
            _errors: [
              "Something went wrong, please contact support at admin@data-noobs.com",
            ],
          },
        };
      }
    } catch {
      return {
        actionError: {
          _errors: [
            "Something went wrong, please contact support at admin@data-noobs.com",
          ],
        },
      };
    }

    return {
      actionSuccess: {
        success: `We sent you a reset link`,
      },
    };
  };
