import { QueryClient, useQuery } from "@tanstack/react-query";
import { USER_QUERY_KEYS, getProfileQuery } from "../api/loginApi";

export interface User {
  email: string;
  fullName: string | null;
}

interface UserContextType {
  user: User | null;
  isLoggedIn: boolean;
}

export const getUserData = async (
  queryClient: QueryClient
): Promise<{ data: User | null; success: boolean } | null> => {
  const cachedUser =
    queryClient.getQueryData<
      { data: User | null; success: boolean } | undefined
    >(USER_QUERY_KEYS) ?? null;

  if (cachedUser) {
    return cachedUser;
  }

  try {
    const data = await queryClient.fetchQuery(getProfileQuery());

    return data;
  } catch (e) {
    return null;
  }
};

export const useUserContext = (): UserContextType => {
  const { data } = useQuery(getProfileQuery());
  const user = data?.data;

  return {
    user: user ?? null,
    isLoggedIn: !!user,
  };
};
