import { forwardRef, Ref, useState } from "react";

export type InputType = {
  border?: boolean;
  className?: string;
  placeholder?: string;
  name?: string;
  type?: "text" | "password" | "email";
  value?: string;
  id?: string;
  label?: string;
  helpText?: string;
  isError?: boolean;
  inputMode?: "text" | "email";
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  Addon?: () => JSX.Element & React.HTMLAttributes<HTMLDivElement>;
  readOnly?: boolean;
};

export const Input = forwardRef(
  (
    {
      className,
      placeholder,
      label,
      name,
      value,
      type = "text",
      isError = false,
      helpText,
      onChange,
      id,
      inputMode = "text",
      Addon,
      readOnly,
    }: InputType,
    ref: Ref<HTMLInputElement> | undefined,
  ) => {
    const [hasValue, setHasValue] = useState(
      value !== undefined && value !== "" && value !== null,
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHasValue(!!e.target.value);
      onChange && onChange(e);
    };

    const hasTwoLines = !!(label && placeholder) || !!(hasValue && label);

    return (
      <div className={className}>
        <div
          className={`relative w-full py-4 px-2 text-md rounded
            border focus-within:-outline-offset-2 focus-within:outline-2 focus-within:outline
            ${
              !isError &&
              "border-gray-100 hover:border-dnBlue focus-within:outline-dnBlue"
            }
            ${
              isError &&
              "border-red-700 hover:border-red-700 focus:border-red-700 focus-within:outline-red-700"
            }
            ${className}`}
        >
          <label htmlFor={name}>
            {hasTwoLines && (
              <div
                className={`absolute top-1.5 left-2 text-xs text-gray-400
                  ${isError ? "text-red-500" : "text-gray-400"}`}
              >
                {label}
              </div>
            )}
            <div className="flex gap-8 w-full">
              <input
                readOnly={readOnly}
                ref={ref}
                inputMode={inputMode}
                type={type}
                id={id}
                value={value}
                className={`transform transition duration-300 ease-in-out grow focus:outline-none
                  ${hasTwoLines && "translate-y-2"}
                  placeholder:text-gray-600`}
                onChange={handleChange}
                placeholder={placeholder || (hasValue ? undefined : label)}
                {...(name ? { name } : null)}
              />
              {Addon && <Addon />}
            </div>
          </label>
        </div>
        {helpText ? (
          <div
            className={`text-xs mt-1 text-left
              ${isError && "text-red-500"}
            `}
          >
            {helpText}
          </div>
        ) : null}
      </div>
    );
  },
);
