import { LoaderFunctionArgs } from "react-router-dom";
import { signup } from "../../api/loginApi";
import { signupSchema } from "../schemaValidators";

export const signUpAction =
  () =>
  async ({ request }: LoaderFunctionArgs) => {
    const formData = await request.formData();
    const fullName = formData.get("fullName") as string;
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;
    const passwordConfirm = formData.get("password-confirmation") as string;

    try {
      const data = {
        email,
        password,
        passwordConfirm,
        fullName,
      };

      const result = signupSchema.safeParse(data);

      if (!result.success) {
        return result?.error?.format();
      }

      const response = await signup({
        email,
        fullName,
        password,
      });

      if (!response.success) {
        if (response.status === 400) {
          return {
            signupError: {
              _errors: [response.data.message],
            },
          };
        }
        return {
          signupError: {
            _errors: [
              "Something went wrong, please contact support at admin@data-noobs.com",
            ],
          },
        };
      }
    } catch (error) {
      return {
        signupError: {
          _errors: [
            "Something went wrong, please contact support at admin@data-noobs.com",
          ],
        },
      };
    }

    return {
      signupSuccess: {
        success: `We sent you an email to make sure it's really you`,
      },
    };
  };
