import { apiClient } from "./apiClient";

export const sendFeedback = async (payload: {
  content: string;
  questionId: string;
  userEmail?: string;
}) => {
  return await apiClient({
    path: "/feedback",
    method: "POST",
    body: payload,
  });
};

export const getFeedbacks = async () => {
  return await apiClient({
    path: "/feedback",
    method: "GET",
  });
};

export const getFeedbacksQuery = () => ({
  queryKey: FEEDBACKS_QUERY_KEYS,
  queryFn: getFeedbacks,
});

const FEEDBACKS_QUERY_KEYS = ["feedbacks"];
