import LoginButton from "./LoginButton";

const Header: React.FC<{ showLoginButton?: boolean }> = ({
  showLoginButton = true,
}) => {
  return (
    <div className="flex flex-row justify-between items-center bg-dnBlue max-h-[95px]">
      <div className="flex items-center">
        {showLoginButton && <LoginButton />}
        <a
          className="bg-pink-400 rounded-lg px-1 py-0.5 text-white transform text-sm mx-6 text-center leading-4"
          target="_blank"
          rel="noopener noreferrer"
          href="https://data-noobs.com/contactus"
        >
          BETA
          <span className="block">Give us feedback</span>
        </a>
      </div>
      <div className="h-full object-cover w-[200px]">
        <a href="https://data-noobs.com/" rel="noopener noreferrer">
          <img src="/logo_white.png" alt="Data Noobs" />
        </a>
      </div>
    </div>
  );
};

export default Header;
