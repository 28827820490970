import { LoaderFunctionArgs } from "react-router-dom";
import { emailConfirmation } from "../../api/loginApi";

export const emailConfirmationAction =
  () =>
  async ({ request }: LoaderFunctionArgs) => {
    const formData = await request.formData();
    const email = formData.get("email") as string | null;

    if (!email) {
      return {
        error: "Something went wrong",
      };
    }

    try {
      await emailConfirmation({ email });
    } catch (error) {
      return {
        error:
          "Invalid login attempt, if you've signed up, please check your email and password or make sure you've verified your email address",
      };
    }

    return {
      actionSuccess: {
        success: `Account successfully confirmed, you can now login into your account and start practicing`,
      },
    };
  };
