import z from "zod";

import { PASSWORD_DESCRIPTION_MESSAGE, PASSWORD_REGEX } from "./constants";

const emailSchemaField = z.string().email("Invalid email address");
const passwordSchemaField = z
  .string()
  .regex(PASSWORD_REGEX, PASSWORD_DESCRIPTION_MESSAGE.join(","));
const fullNameSchemaField = z
  .string()
  .min(2, "Full name must be at least 2 characters long");
const passwordConfirmSchemaField = z.string();
const passwordLengthSchemaField = z
  .string()
  .min(8, "Password must be at least 8 characters");

export const signupSchema = z
  .object({
    email: emailSchemaField,
    fullName: fullNameSchemaField,
    password: passwordSchemaField,
    passwordConfirm: passwordConfirmSchemaField,
  })
  .refine(
    (data) => {
      return data.password === data.passwordConfirm;
    },
    {
      message: "Passwords don't match",
      path: ["password-confirmation"],
    },
  );

export const signupOrLoginSchema = z.object({
  email: emailSchemaField,
  fullName: fullNameSchemaField,
});

export const resetPasswordSchema = z
  .object({
    requestId: z
      .string()
      .min(
        1,
        "Something went wrong, please contact support at admin@data-noobs.com",
      ),
    password: passwordSchemaField,
    passwordConfirm: passwordConfirmSchemaField,
  })
  .refine(
    (data) => {
      return data.password === data.passwordConfirm;
    },
    {
      message: "Passwords don't match",
      path: ["password-confirmation"],
    },
  );

export const forgotPasswordSchema = z.object({
  email: emailSchemaField,
});

export const loginSchema = z.object({
  email: emailSchemaField,
  password: passwordLengthSchemaField,
});
