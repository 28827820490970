export interface QuestionData {
  id: number;
  title: string;
  description: string;
  correctAnswer?: Record<string, unknown>[];
  ignoreOrder: boolean;
  hint: string;
  solution: string;
  difficulty: number;
  tags: string[];
}

export interface QuestionDataNew {
  id: string;
  title: string;
  description: string;
  correctAnswer?: Record<string, unknown>[];
  ignoreOrder: boolean;
  hint: string;
  solution: string;
  difficulty: number;
  tags: string[];
  position: number;
  isVisible: boolean;
  outputSampleHeaders: string[];
  outputSampleValues: string[];
}

export const TABS = {
  DESCRIPTION: "Description",
  ERD: "Data Diagram",
  HINT: "Hint",
  SOLUTION: "Solution",
  OUTPUT_SAMPLE: "Output Sample",
};
