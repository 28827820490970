import { LoaderFunctionArgs } from "react-router-dom";
import { resetPassword } from "../../api/loginApi";
import { resetPasswordSchema } from "../schemaValidators";

export const resetPasswordAction =
  () =>
  async ({ request }: LoaderFunctionArgs) => {
    const formData = await request.formData();
    const requestId = formData.get("requestId");
    const password = formData.get("password");
    const passwordConfirm = formData.get("password-confirmation");

    if (!requestId) {
      return {
        signupError: {
          _errors: [
            "Something went wrong, please contact support at admin@data-noobs.com",
          ],
        },
      };
    }

    const data = {
      requestId,
      password,
      passwordConfirm,
    };

    const result = resetPasswordSchema.safeParse(data);
    if (!result.success) {
      return result?.error?.format();
    }

    try {
      const res = await resetPassword(result.data);
      if (!res.success) {
        if (res.status === 429 || res.status === 400) {
          return {
            actionError: {
              _errors: [res.data],
            },
          };
        } else {
          return {
            actionError: {
              _errors: [
                "Something went wrong, please contact support at admin@data-noobs.com",
              ],
            },
          };
        }
      }
    } catch (error) {
      return {
        actionError: {
          _errors: [
            "Something went wrong, please contact support at admin@data-noobs.com",
          ],
        },
      };
    }

    return {
      actionSuccess: {
        success: `Reset password completed successfully. You can now login with your new password.`,
      },
    };
  };
