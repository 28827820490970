import {
  useActionData,
  useLocation,
  useNavigation,
  Form,
  Link,
} from "react-router-dom";

import { AuthenticationLayout } from "../layout";
import Button from "../../uiKit/button";
import { InputPassword } from "../../uiKit/passwordInput";

export const ResetPasswordForm = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const requestId = params.get("r") as string;
  const expirationDate = params.get("d");

  const navigation = useNavigation();
  const isLoggingIn = navigation.formData?.get("username") != null;

  const actionData = useActionData() as Record<
    string,
    { _errors?: string[]; success?: string }
  >;

  if (!requestId || !expirationDate) {
    return (
      <AuthenticationLayout
        title="Reset your password"
        subTitle="This link is not valid, please contact support at admin@data-noobs.com"
      />
    );
  }

  if (Number(expirationDate) < new Date().getTime()) {
    return (
      <AuthenticationLayout
        title="Reset your password"
        subTitle="This link has expired, please contact support at admin@data-noobs.com"
      >
        <Link to="/login">
          <Button>Back to login</Button>
        </Link>
      </AuthenticationLayout>
    );
  }

  if (actionData?.actionError?._errors) {
    return (
      <AuthenticationLayout
        title="Reset your password"
        subTitle={actionData?.actionError?._errors}
      />
    );
  }

  if (actionData?.actionSuccess?.success) {
    return (
      <AuthenticationLayout
        title="Reset your password"
        subTitle={actionData.actionSuccess.success}
      >
        <Link to="/login">
          <Button>Go to login</Button>
        </Link>
      </AuthenticationLayout>
    );
  }

  return (
    <AuthenticationLayout title="Reset your password">
      <Form method="post" replace className="flex flex-col gap-4 mx-2">
        <input type="hidden" name="requestId" value={requestId} />

        <div className="flex flex-col relative">
          <InputPassword
            isError={!!actionData?.password?._errors?.length}
            helpText={actionData?.password?._errors?.[0]}
            name="password"
            placeholder="Password"
          />
        </div>

        <div>
          <InputPassword
            isError={!!actionData?.["password-confirmation"]?._errors?.length}
            helpText={actionData?.["password-confirmation"]?._errors?.[0]}
            name="password-confirmation"
            placeholder="Confirm password"
          />
        </div>

        <Button type="submit" disabled={isLoggingIn}>
          Reset password
        </Button>
      </Form>
    </AuthenticationLayout>
  );
};
