type AppEnv = {
  serverUrl: string;
  clientUrl: string;
  serverPort?: number;
  env: string;
};

const production: AppEnv = {
  serverUrl: "https://data-noobs-server-e3930493fa5c.herokuapp.com",
  clientUrl: "https://data-noobs-client-10005e625cee.herokuapp.com",
  env: "prod",
};

const development: AppEnv = {
  env: "local",
  serverUrl: "http://localhost",
  clientUrl: "http://localhost",
  serverPort: 3300,
};

const reactEnvironment = process.env.NODE_ENV as string;

let localConf = production;

if (reactEnvironment === "development") {
  localConf = development;
}

const environment = localConf;

export default environment;
