import React, { CSSProperties, useEffect, useState } from "react";
import { Parser } from "node-sql-parser";
import { apiClient } from "../api/apiClient";
import SqlTable from "./sqlTable";
import { isArray, isEqual } from "lodash";
import AceEditor from "react-ace";
import "brace/mode/pgsql";
import "brace/theme/terminal";
import { ToastContainer, TypeOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SyncLoader } from "react-spinners";
import { QuestionInfo } from "./questionInfo";
import { DifficultyLevel } from "./difficultyLevel";
import TagManager from "react-gtm-module";
import { useMixpanel } from "react-mixpanel-browser";
import { useNavigate, useParams } from "react-router-dom";
import { QUESTION_ID_PATH_PARAM } from "../App";
import { questions } from "./questionsData";
import { FaArrowLeft } from "react-icons/fa";
import SuccessPopup from "../components/SuccessPopup";
import { useUserContext } from "../authentication/userContext";
import Button from "../uiKit/button";
import {
  ANSWERS_STORAGE_NAME,
  QUERIES_STORAGE_NAME,
} from "../authentication/constants";

interface NodeSqlParserError {
  message: string;
  found: string;
  location: {
    start: { offset: number; line: number; column: number };
    end: { offset: number; line: number; column: number };
  };
}

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
};

const SqlEditor: React.FC = () => {
  const [query, setQuery] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Record<string, unknown>[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeToastId, setActiveToastId] = useState<number | string | null>(
    null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useUserContext();
  const navigate = useNavigate();
  const params = useParams();
  const questionId = params[QUESTION_ID_PATH_PARAM] as string;
  const questionData = questions.find((q) => q.id === Number(questionId));

  const mixpanel = useMixpanel();

  useEffect(() => {
    const queriesLocalStorage = localStorage.getItem(QUERIES_STORAGE_NAME);
    const queries = queriesLocalStorage ? JSON.parse(queriesLocalStorage) : {};
    setQuery(queries[questionId] || "");

    setError(null);
    setData(null);
  }, [questionData, questionId]);

  if (!questionData) return <div>Something went wrong...</div>;

  const onChange = (value: string) => {
    setQuery(value);
    setError(null);

    const queriesLocalStorage = localStorage.getItem(QUERIES_STORAGE_NAME);
    const queries = queriesLocalStorage ? JSON.parse(queriesLocalStorage) : {};

    const updatedQueries = { ...queries, [questionId]: value };
    localStorage.setItem(QUERIES_STORAGE_NAME, JSON.stringify(updatedQueries));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const updateCorrectAnswer = (id: number) => {
    const answeredQuestionsLocalStorage =
      localStorage.getItem(ANSWERS_STORAGE_NAME);
    const answeredQuestions = answeredQuestionsLocalStorage
      ? JSON.parse(answeredQuestionsLocalStorage)
      : {};

    const updatedAnsweredQuestions = { ...answeredQuestions, [id]: true };
    localStorage.setItem(
      ANSWERS_STORAGE_NAME,
      JSON.stringify(updatedAnsweredQuestions)
    );
  };

  const handleRunQuery = async (isSubmitted: boolean, questionId: number) => {
    try {
      const parser = new Parser();
      const ast = parser.parse(query, {
        database: "Postgresql",
      });
      setData(null);
      setError(null);
      setIsLoading(true);
      const response = await apiClient({
        path: "/run-query",
        method: "post",
        body: {
          ast,
          questionId,
        },
      });
      const success = response.success;
      const answerIsCorrect = isAnswerCorrect(response.data);

      TagManager.dataLayer({
        dataLayer: {
          event: "run_query",
          questionId: questionData?.id,
          query,
          answerIsCorrect,
          isSubmitted,
        },
      });

      if (mixpanel) {
        mixpanel.track("run_query", {
          questionId: questionData?.id,
          query,
          answerIsCorrect,
          isSubmitted,
          email: user?.email,
          name: user?.fullName,
          errorMessage: success ? null : response.data,
        });
      }

      if (questionData && isSubmitted) {
        if (success) {
          if (answerIsCorrect) {
            setIsPopupOpen(true);
            toast.dismiss();
          } else {
            showToast("That's not exactly right. Try again", "info");
          }
        } else {
          showToast(
            "There's something wrong with the query. Fix it and try again",
            "error"
          );
        }

        if (answerIsCorrect) {
          updateCorrectAnswer(questionData.id);
        }
      }
      setData(success ? response.data : null);
      setIsLoading(false);
      setError(success ? null : response.data);
    } catch (error) {
      setIsLoading(false);
      // @ts-ignore
      if (!error?.location?.start) {
        console.log("error:", error);
        mixpanel?.track("error", {
          type: "client_query_error",
          questionId: questionData?.id,
          query,
          email: user?.email,
          name: user?.fullName,
          error,
        });
        showToast("Something went wrong", "error");
      } else {
        const errorMsg = parseSqlParserError(error as NodeSqlParserError);
        isSubmitted &&
          showToast(
            "There's a parsing error in your query. Fix it and try again",
            "error"
          );
        setError(errorMsg);
      }
    }
  };

  const showToast = (text: string, type: TypeOptions) => {
    if (activeToastId) {
      toast.update(activeToastId, {
        render: text,
        onClose: onToastClose,
        type,
      });
    } else {
      const toastId = toast(text, {
        onClose: onToastClose,
        type,
      });
      setActiveToastId(toastId);
    }
  };

  const onToastClose = () => {
    setActiveToastId(null);
  };

  const isAnswerCorrect = (data: Record<string, unknown>[]): boolean | null => {
    if (!questionData?.correctAnswer || !isArray(data)) return null;
    const answerIsCorrect = areArraysEqual(
      data,
      questionData.correctAnswer,
      questionData.ignoreOrder
    );

    return answerIsCorrect;
  };

  const areArraysEqual = (
    arr1: Record<string, unknown>[],
    arr2: Record<string, unknown>[],
    ignoreOrder: boolean
  ): boolean => {
    if (arr1.length !== arr2.length) return false;

    const roundNumber = (value: unknown): unknown => {
      if (typeof value === "number") {
        return Math.round(value * 100) / 100;
      }
      return value;
    };

    if (ignoreOrder) {
      const stringify = (obj: Record<string, unknown>) => JSON.stringify(obj);
      arr1.sort((a, b) => stringify(a).localeCompare(stringify(b)));
      arr2.sort((a, b) => stringify(a).localeCompare(stringify(b)));
    }

    for (let i = 0; i < arr1.length; i++) {
      const values1 = Object.values(arr1[i]).map(roundNumber);
      const values2 = Object.values(arr2[i]).map(roundNumber);
      const isEql = isEqual(values1.sort(), values2.sort());
      if (!isEql) return false;
    }

    return true;
  };

  const parseSqlParserError = (error: NodeSqlParserError): string => {
    const {
      found,
      location: {
        start: { line, column },
      },
    } = error;

    const parsedFound = found === "\n" ? "\\n" : found;
    return `Syntax error at line ${line}, character ${column}: invalid key "${parsedFound}"`;
  };

  const buttonStyle = query === "" ? "cursor-not-allowed" : "";

  return (
    <div className="flex flex-col m-3 md:w-full w-fit h-fit">
      <div className="flex items-start mt-4 justify-between mr-3">
        <button
          onClick={() => navigate("/")}
          className="flex items-center text-gray-600 hover:text-gray-800 mr-4"
        >
          <FaArrowLeft className="mr-2" />
          <span>Back to Questions</span>
        </button>
      </div>
      <div className="text-start text-gray-800 mb-4 flex flex-row gap-6 items-center">
        <div className="font-bold text-2xl">{questionData.title}</div>
        <DifficultyLevel level={questionData?.difficulty} />
      </div>
      <div className="flex flex-col md:flex-row w-full flex-1 gap-2">
        <div className="w-full md:w-1/3 bg-gray-100 rounded-md max-h-96 overflow-y-auto">
          <QuestionInfo questionData={questionData} />
        </div>
        <div className="hidden md:block border-l border-gray-300 mx-4"></div>
        <div className="w-full md:w-2/3">
          <div className="mr-0 md:mr-8">
            <AceEditor
              placeholder="Enter your SQL query here"
              mode="pgsql"
              theme="terminal"
              name="editor"
              onChange={onChange}
              fontSize={14}
              lineHeight={24}
              value={query}
              style={{
                width: "100%",
                maxHeight: "331px",
                borderRadius: "6px",
              }}
            />
          </div>
          <div className="flex items-start justify-end space-x-4 my-2 md:mr-8">
            {error && (
              <div className="sql-editor__error text-red-500 mt-2">
                Error: {error}
              </div>
            )}
            <Button
              disabled={query === ""}
              onClick={() => handleRunQuery(false, questionData.id)}
              className={`my-1 min-w-28 max-w-28 ${buttonStyle}`}
              variant="secondary"
            >
              Run Query
            </Button>
            <Button
              disabled={query === ""}
              onClick={() => handleRunQuery(true, questionData.id)}
              className={`my-1 w-28 ${buttonStyle}`}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
      <div className="h-4"></div>
      <SyncLoader
        color={"#6456FF"}
        loading={isLoading}
        cssOverride={override}
        size={30}
      />
      {data !== null && (
        <div className="w-full">
          <SqlTable data={data} />
          <div className="mx-2 mt-2 text-dnBlue text-xs">
            {data.length} rows returned
          </div>
        </div>
      )}
      {isPopupOpen && (
        <SuccessPopup
          onClose={handleClosePopup}
          questionId={Number(questionId)}
        />
      )}
    </div>
  );
};

export default SqlEditor;
