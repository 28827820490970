import React from "react";

type ButtonProps = {
  variant?: "primary" | "secondary";
  className?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  variant = "primary",
  className = "",
  children,
  ...props
}) => {
  const baseClass =
    "py-2 px-4 rounded-lg focus:outline-none transition-colors duration-200";

  const variantClass =
    variant === "primary"
      ? "bg-dnPurple text-white hover:bg-dnPurpleDark"
      : "bg-white border border-dnPurple text-dnPurple hover:bg-gray-100";

  return (
    <button className={`${baseClass} ${variantClass} ${className}`} {...props}>
      {children}
    </button>
  );
};

export default Button;
