const PageHeader = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between mt-6">
      <div className="text-5xl text-dnBlue font-semibold text-center mb-4 md:mb-0 lg:flex-grow">
        Master SQL Interview Questions for Success
      </div>
    </div>
  );
};

export default PageHeader;
