export const PASSWORD_DESCRIPTION_MESSAGE = [
  "Passwords need to consist of a minimum of 8 characters and include the following:",
  "at least 1 uppercase letter",
  "1 lowercase letter",
  "2 numbers",
  "and 1 symbol",
];

// (?=.*[A-Z]): Positive lookahead for at least one uppercase character.
// (?=.*[a-z]): Positive lookahead for at least one lowercase character.
// (?=.*\d.*\d): Positive lookahead for at least two digits.
// (?=.*[!@#$%^&*()\-=_+{}\[\]:;<>,.?/~\\]): Positive lookahead for at least one special character. You can customize the special characters within the square brackets.
// .{8,25}: Matches 8 to 25 characters of any type.
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[\W_]).{8,25}$/;

export const QUERIES_STORAGE_NAME = "writtenQueries";
export const ANSWERS_STORAGE_NAME = "answeredQuestions";
