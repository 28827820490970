const ValueProposition: React.FC = () => {
  return (
    <div className="container mx-auto mt-6 mb-2 bg-gray-50 rounded-3xl md:w-1/2 py-6 px-4">
      <div className="grid grid-cols-3 gap-4 text-center">
        <div>
          <img
            src={`/business_person.png`}
            alt="Experience Icon"
            className="mx-auto h-16 w-16"
          />
        </div>
        <div>
          <img
            src={`/business_report.png`}
            alt="Business Scenarios Icon"
            className="mx-auto h-16 w-16"
          />
        </div>
        <div>
          <img
            src={`/database.png`}
            alt="No Installation Icon"
            className="mx-auto h-16 w-16"
          />
        </div>
        <div className="text-md font-medium">
          Created by a Senior Data Analyst with 7 Years of Industry Experience
        </div>
        <div className="text-md font-medium">
          Real-World Business Scenarios for Practical Learning
        </div>
        <div className="text-md font-medium">
          No Database Downloads or Software Installation Required
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
